import React, { Component } from 'react';

/* THIS PAGE HAS BEEN MOVED TO DATA VISUALIZATION/DATA TABLE */
export default class LegendOverviewDesign extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate('/components/data-visualization/legend/design');
  }

  render() {
    return <div />;
  }
}
